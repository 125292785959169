import qr from "./icons/Qr scanner.svg";
import teclado from "./icons/teclado.svg";

const url = "https://savethechildrenmx.org"

function showQR(evt){
    document.querySelector(".card").style.display="none"
    document.querySelector(".Scanner").style.display="unset"

    var cameras = document.querySelectorAll("video");
    if (cameras.length > 1){
        document.querySelector("video:nth-child(2)").remove()
    }
}

function showManual(evt){
    document.querySelector(".card").style.display="none"
    document.querySelector(".manual_register").style.display="unset"

    fetch(url+"/events",{
        method:'POST',
        body: ''
    }).then(resp=>{
        return resp.text()
    }).then(txt=>{
        var json = JSON.parse(txt)
        json.forEach(element=>{
            var option = document.createElement("option")
            option.value = element.evento_id
            option.textContent = element.nombre
            document.getElementById("evento").appendChild(option)
        })
    })
}
export default function Card(){
    return (
      <div className={"card"}>
            <div className="card-header">
                SELECCIONA UNA OPCION
            </div>
          <div className="card-body">
              <div className="item" onClick={showQR}>
                  <img src={qr} alt=""/>
                  Escaneo de QR
              </div>
              <div className="item" onClick={showManual}>
                  <img src={teclado} alt=""/>
                  Registro manual
              </div>
          </div>
      </div>
    );
}