import React from "react";
import items from "./plugins/Buscador";

const url = "https://savethechildrenmx.org"

function searchBuy(evt){
    var compra = evt.target.value

    document.querySelectorAll(".guest_list td:first-child").forEach(element=>{
        if(element.textContent.toUpperCase().includes(compra.toUpperCase())){
           element.parentElement.style.display=""
        }else{
            element.parentElement.style.display="none"
        }
    })

}


function searchBuyPerEmail(evt){
    var compra = evt.target.value


    document.querySelectorAll(".guest_list td:nth-child(2)").forEach(element=>{
        if(element.textContent.toUpperCase().includes(compra.toUpperCase())){
            element.parentElement.style.display=""
        }else{
            element.parentElement.style.display="none"
        }
    })
}

function fetchBuys(evt){
    var event = evt.target.value

    var form = new FormData();
    form.append("event",event)

    fetch(url+"/buys",{
        method:'post',
        body: form
    }).then(resp=>{
        return resp.text()
    }).then(txt=>{
        var data = JSON.parse(txt)
        console.log(data)

        document.querySelector(".guest_list tbody").innerHTML = ""
        data.forEach(element=>{
            var tr = document.createElement("tr")

            var td = document.createElement("td")
            td.textContent =  element.comprador
            tr.appendChild(td)

            td = document.createElement("td")
            td.textContent =  element.correo
            tr.appendChild(td)

            td = document.createElement("td")
            var button = document.createElement("button")
            button.textContent =  "Seleccionar"
            button.setAttribute("correo",element.correo)
            button.setAttribute("event",event)

            button.addEventListener('click',evt=>{
                var context = new (window.AudioContext || window.webkitAudioContext)();
                var Sonidos= [261,277,293,311,329,349,369,392,415,440,466,493];

                function Sonido(nota){
                    //creamos oscilador
                    var osc = context.createOscillator();

                    // admite: sine, square, sawtooth, triangle
                    osc.type = 'triangle';

                    osc.frequency.value=Sonidos[nota];

                    //asignamos el destino para el sonido
                    osc.connect(context.destination);
                    //iniciamos la nota
                    osc.start();
                    //detenemos la nota medio segundo despues
                    osc.stop(context.currentTime + .3);
                }

                Sonido(10);
                getBuy(evt.target.getAttribute("correo"),evt.target.getAttribute("event"))

            })

            td.appendChild(button)
            tr.appendChild(td)

            document.querySelector(".guest_list tbody").append(tr)
        })
    })
}


async function getBuy(email,event){
    var formData = new FormData();

    formData.append("email",email);
    formData.append("event",event);

    var result = await fetch(items.url+ "/attendanceRecord",{
        method:"post",
        body: formData
    }).then(resp =>{
        if (resp.status === 200){
            return resp.text()
        }else{
            return null;
        }
    })

    result = JSON.parse(result)

    if (result != null){
        document.querySelector(".manual .owner .value").textContent = result[0].comprador
        document.querySelector(".manual .table .value").textContent = result[0].table_id
        document.querySelector(".manual .event .value").textContent = result[0].nombre
        document.querySelector(".manual .restantes .value").textContent = result[1].restantes-1
        if (result.status === 200){
            document.querySelector(".manual .result-item-status").classList.add("success")
            document.querySelector(".manual .result-item-status").classList.remove("danger")
            document.querySelector(".manual .result-item-status").textContent = "Acceso exitoso"
            if (result[0].type == 1){
                document.querySelector(".manual h1").textContent="Donante activo"
            }else{
                document.querySelector(".manual h1").textContent="Donante nuevo"
            }
            document.querySelector("#comprador").value=""
            document.querySelector("#email_comprador").value=""
        }else{
            if (result.status === 400){
                document.querySelector(".manual .result-item-status").classList.add("danger")
                document.querySelector(".manual .result-item-status").classList.remove("success")
                document.querySelector(".manual .result-item-status").textContent = "Sin lugares disponibles"
            }
        }
    }else{
        alert("erro en servidor")
    }
}



export default function ManualRegister(){

    function validateEmail(evt){
        var email_regex = new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$");
        var send = document.querySelector("button.send");

        if (email_regex.test(evt.target.value)){
            send.removeAttribute("disabled")
        }else{
            send.setAttribute("disabled","disabled")
        }
    }

    async function sendDatas(evt){
        var searcher =new URLSearchParams(window.location.search);
        var event = searcher.get("event");
        var email = document.getElementById("email").value;
        var formData = new FormData();
        formData.append("email",email);
        formData.append("event",event);

        if(event == null){
            alert("Error: no podemos enviar una solicitud si no tenemos un evento");
        }else{

            var result = await fetch("https://savethechildrenmx.org/attendanceRecord",{
                method:"post",
                body: formData
            }).then(resp =>{
                if (resp.status === 200){
                    return resp.text()
                }else{
                    return null;
                }
            })

        }
    }


    return(
        <>
            <div className="manual_register">
                <div className="card-header">
                    Registro manual
                </div>
                <div className="card-body">
                    <div className="manual__register-item">
                        <div className="searcher">
                            <select name="" id="evento" onChange={fetchBuys}>
                                <option value="" disabled selected> Evento</option>
                            </select>
                            <input type="search" id="comprador" aria-autocomplete={false} placeholder="Nombre del comprador" onKeyUp={searchBuy}/>
                            <input type="search" id="email_comprador" aria-autocomplete={false} placeholder="Correo del comprador" onKeyUp={searchBuyPerEmail}/>
                        </div>
                        <div className="guest_list">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Correo</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="item result manual">
                        <h1>Resultado de la búsqueda</h1>
                        <div className="result-item owner">
                            <label htmlFor="">Propietario:</label>
                            <div className="value"></div>
                        </div>
                        <div className="result-item table">
                            <label htmlFor="">Mesa: </label>
                            <div className="value"></div>
                        </div>
                        <div className="result-item restantes">
                            <label htmlFor="">Lugares restantes: </label>
                            <div className="value"></div>
                        </div>
                        <div className="result-item event">
                            <label htmlFor="">Evento: </label>
                            <div className="value"></div>
                        </div>
                        <div className="result-item-status">
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}