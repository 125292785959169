import React, {useEffect, useState} from "react";
import {Html5QrcodeScanner} from "html5-qrcode";
import items from "./plugins/Buscador";

export default function Scanner(){
    const [scanResult, setScanResult] = useState(null);

    useEffect(() => {
        const scanner = new Html5QrcodeScanner('reader', {
            qrbox: {
                width: 250,
                height: 250,
            },
            fps: 5,
        });

        document.querySelector(".result-item-continuar").addEventListener('click',evt=>{
            setTimeout(()=>{
                if(scanner.getState()===1){
                    scanner.render(success, error);
                }
            })
            document.querySelector(".item.result").style.bottom="-145%"
        })

        scanner.render(success, error);

        function success(result) {

            var context = new (window.AudioContext || window.webkitAudioContext)();
            var Sonidos= [261,277,293,311,329,349,369,392,415,440,466,493];

            function Sonido(nota){
                //creamos oscilador
                var osc = context.createOscillator();

                // admite: sine, square, sawtooth, triangle
                osc.type = 'triangle';

                osc.frequency.value=Sonidos[nota];

                //asignamos el destino para el sonido
                osc.connect(context.destination);
                //iniciamos la nota
                osc.start();
                //detenemos la nota medio segundo despues
                osc.stop(context.currentTime + .3);
            }

            Sonido(10);

            if (window.innerWidth<=800){
                document.querySelector(".item.result").style.bottom="0%"
                scanner.clear();
            }

            var data = JSON.parse(result)
            getBuy(data)

            if (window.innerWidth <= 700){
                document.querySelector(".item.result button").style.display="unset"
            }
        }

        function error(err) {
            console.warn(err);
        }
    }, []);

    async function getBuy(data=JSON){
        var formData = new FormData();

        formData.append("email",data.email);
        formData.append("event",data.event);

            var result = await fetch(items.url+ "/attendanceRecord",{
                method:"post",
                body: formData
            }).then(resp =>{
                if (resp.status === 200){
                    return resp.text()
                }else{
                    return null;
                }
            })

            result = JSON.parse(result)

            if (result != null){
                document.querySelector(".scanner .owner .value").textContent = result[0].comprador
                document.querySelector(".scanner .table .value").textContent = result[0].table_id
                document.querySelector(".scanner .event .value").textContent = result[0].nombre
                document.querySelector(".scanner .restantes .value").textContent = result[1].restantes-1
                if (result.status === 200){
                    document.querySelector(".scanner .result-item-status").classList.add("success")
                    document.querySelector(".scanner .result-item-status").classList.remove("danger")
                    document.querySelector(".scanner .result-item-status").textContent = "Acceso exitoso"
                    if (result[0].type == 1){
                        document.querySelector(".scanner h1").textContent="Donante activo"
                    }else{
                        document.querySelector(".scanner h1").textContent="Donante nuevo"
                    }
                }else{
                    if (result.status === 400){
                        document.querySelector(".scanner .result-item-status").classList.add("danger")
                        document.querySelector(".scanner .result-item-status").classList.remove("success")
                        document.querySelector(".scanner .result-item-status").textContent = "Sin lugares disponibles"
                    }
                }
            }else{
                alert("erro en servidor")
            }
    }


    return(
        <div className={"Scanner"}>
            <div className="card-header">
                Registro por qr
            </div>
            <div className="card-body">
                <div className="item">
                    <div id="reader"></div>
                </div>
                <div className="item result scanner">
                    <h1>Resultado de la búsqueda</h1>

                    <div className="result-item owner">
                        <label htmlFor="">Propietario:</label>
                        <div className="value"></div>
                    </div>
                    <div className="result-item table">
                        <label htmlFor="">Mesa: </label>
                        <div className="value"></div>
                    </div>
                    <div className="result-item restantes">
                        <label htmlFor="">Lugares restantes: </label>
                        <div className="value"></div>
                    </div>
                    <div className="result-item event">
                        <label htmlFor="">Evento: </label>
                        <div className="value"></div>
                    </div>
                    <div className="result-item-status">
                    </div>
                    <button className="result-item-continuar">
                        Continuar escaneando
                    </button>
                </div>
            </div>
        </div>
    )
}