import React, { useEffect, useState } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';
import './css/App.css';
import Card from "./Card";
import Scanner from "./Scanner";
import ManualRegister from "./ManualRegister";
function App() {


  return (
      <div className="App">
        <Card/>
        <Scanner/>
        <ManualRegister/>
      </div>
  );
}

export default App;   